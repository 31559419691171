import * as SentryVue from '@sentry/vue'
import Vue from 'vue'

global.Sentry = class {
    static init(dsn, environment, user) {
        SentryVue.init({
            Vue,
            dsn,
            //tunnel: Router.generate('error-reporting-tunnel'),
            environment,
            ignoreErrors: ['TypeError: NetworkError when attempting to fetch resource.'],
            integrations: [SentryVue.browserTracingIntegration()],

            trackComponents: true,
            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0,

        });
        SentryVue.setUser({username: user});
    }
};